
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body {
    background-color: #fff;
}
.spacing {
	margin: 30px 0;
	@media (max-width: $screen-md-max) { margin: 20px 0; }
}
pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
span.yellow {
    color: $yellow;
}
a.yellow {
    cursor: pointer;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition:.5s;
    
    &:hover {
        color: $yellow;
    }
}
.post-link { 
    color: black; 
    &:hover, &:focus, &:active, &:active:hover, {
        color: $black;
        text-decoration: underline;
    }
}
.pagination {
    > li > a {
        
        color: black; 
        &:hover, &:focus, &:active, &:active:hover, {
            color: black;

            background-color: lighten($yellow, 10%);
        }

    }
    > li.active {
        > span, > span:hover {
            color: $black;
            background-color: $yellow;
            border-color: darken($yellow, 10%);
        }
        
    }
}
.form-control:focus {
    border-color: $yellow;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 189, 0, 0.6);
}
.btn.btn-danger {
    color: white;
}
.btn.btn-default {
    border-color: darken($yellow, 10%);
    color: black;
    &:hover,
    &:focus:active {
        background-color: lighten($yellow, 10%);
        color: black;
    }
}
.btn.btn-primary {
    border-color: darken($yellow, 10%);
    color: black;
    background-color: #ffbd00;
    &:hover,
    &:focus:active {
        background-color: lighten(#ffbd00, 10%);
        color: black;
    }
}
.navbar {
    z-index: 1000;
}
.navbar.home {
    height: 55px;
}
.navbar-logo img {
    margin-top: -8px;
    width: 240px;
    @media (max-width: $screen-xs-max) { 
        width: 150px;
        margin-top: 0px;
    }
    @media (max-width: $screen-md-max) { 
        width: 200px;
        margin-top: -3px;
    }
}
.navbar-collapse { background-color: #222; }
.navbar {
    @media (max-width: $screen-md-min) {
        .navbar-right li a {
            text-align: center;
        }
        .dropdown { text-align: center; }
    }
}
#mainNav {
    background-color: #222;
    color: white;
    .link {
        color: white;
        -o-transition:.5s;
        -ms-transition:.5s;
        -moz-transition:.5s;
        -webkit-transition:.5s;
        transition:.5s;
        &:hover {
            color: $yellow;
        }
    }
}
#mainNav2 {
	background-color: #fff;
    color: $text-color;
	border-color: #fff;
	border-bottom-color: #04abe9;
    .link {
        color: $text-color;
        -o-transition:.5s;
        -ms-transition:.5s;
        -moz-transition:.5s;
        -webkit-transition:.5s;
        transition:.5s;
        &:hover {
            color: blue;
        }
    }
}
.btn-language {
    margin-top: 8px;
    margin-left: 10px;
    padding: 5px 10px;
    width: 160px;
    background-color: $body-bg;
    background-color: #222;
    border-color: $yellow;
    &:hover, &.active, &:hover {
        background-color: $yellow;
        color: $black;
    }
    .lng-title { float: left; }
    .lng-img img { margin-top: -2px; }
    .lng-caret { margin-left: 5px; }
    @media (max-width: $screen-md-max) { 
        width: 125px;
    }
}

.lng-menu {
    padding: 0px;
    background-color: #222;
    li a {
        color: white;
        padding: 5px 20px;
        width: 100%;
        .lng-img img { margin-top: -2px; }
        &:hover {
            background-color: $yellow;
            span { color: $black; }
        }
    }
    li.active a,
    &.active a:hover {
        background-color: $yellow !important;
        span { color: $black; }
    }
}
.subtitle-alt {
	margin:-24px 2px 15px 2px;
	font-size:20px;
	padding:10px;
	border-bottom: solid 1px $gray-light;
	box-shadow: 0px 0px 5px 2px $gray-light;
	@media (max-width: $screen-md-max) { font-size: 18px; padding: 5px; margin-bottom: 5px; }
	h1 { font-size:20px; margin: 0; }
}
.admin-nav {
    width: 100%;
    border: 1px solid $yellow;
    li.active, li.active:hover {
        
        > a { color: $black;background-color: $yellow; cursor: default;}
    }
    /*.link {
        color: $text-color;
        &:hover {
            color: $yellow;
        }
    }*/
}

.container.home {
    min-height: 600px;
}
.filter {
    .form-group {
        //margin-right: 5px;
    }
}

.view-post {
    .view-post-inner {
        padding: 10px;
        @media (max-width: $screen-xs-max) { padding: 0px; }
        .post-inner {      
            padding: 5px;
            border: 5px solid black;
            @media (max-width: $screen-xs-max) { border-width: 3px; padding: 2px; }
            .post-content {
                background-color: white;
                border-radius: 20px;
                padding: 50px;
                border: 1px solid black;
                @media (max-width: $screen-xs-max) { padding: 25px; border-radius: 10px; }
                .post-cat {
                    font-size: 20px;
                    margin-bottom: 20px;
                    display: inline-block; 
                    background: black; 
                    color: white; 
                    padding: 5px 20px; 
                    border-radius: 10px; 
                    margin-top: -5px;
                    @media (max-width: $screen-xs-max) { font-size: 18px; }
                }
                .post-img img {
                    height: 250px;
                    margin: 20px 0;
                    @media (max-width: $screen-xs-max) { height: 180px; max-width: 180px; }
                }
                .post-title {
                    font-size: 25px;
                    color: black;
                    @media (max-width: $screen-xs-max) { font-size: 20px; }
                }
                .post-subtitle {
                    font-size: 18px;
                    @media (max-width: $screen-xs-max) { font-size: 16px; }
                }
                .post-desc {
                    padding: 20px 40px;
                    @media (max-width: $screen-xs-max) { padding: 10px 0; }
                }
                
                &.double {
                    
                    .post-img img {
                        @media (max-width: $screen-xs-max) { 
                            height: 150px; max-width: 150px;
                        }
                    }
                }
            }
        }
    }
}
.post {
    padding: 2px;
    
    .post-inner {
        border-radius: 15px; 
        border: 1px solid gray;
        
        .post-content {
            padding: 20px;
            @media (max-width: $screen-xs-max) { padding: 20px 10px; }
            .post-cat {
                margin: 10px auto; 
                display: inline-block; 
                background: black; 
                color: white; 
                padding: 5px 20px; 
                border-radius: 10px; 
                margin-top: -5px;
            }       
            .post-img img {
                margin-bottom: 20px;
                margin-top: 10px;
            }
            a.post-title {
                color: $text-color;
                font-weight: bold;
            }
            a.view-post-link {
                color: black;
            }
            
        }
    }
}
.progress-parent {
    display: none;
    border: 1px solid $black;
    margin-top: 4px;
    border-radius: 5px;
    overflow: hidden;
    width: 80%;
    .bar {
        height: 15px;
        background: $yellow;
    }
}

.post.post-small {
    .post-inner .post-content {
        .post-img img {
            height: 80px;
            @media (max-width: $screen-xs-max) { height: 60px; }
        }
        a.post-title {
            font-size: 16px;
        }
    }
}
.post.post-medium {
    .post-inner .post-content {
        .post-img img {
            height: 120px;
            @media (max-width: $screen-xs-max) { height: 90px; }
        }
        a.post-title {
            font-size: 18px;
        }
    }
}
.post.post-large, .post.post-large-2 {
    .post-inner .post-content {
        .post-img img {
            height: 200px;
            @media (max-width: $screen-xs-max) { height: 120px; }
        }
        a.post-title {
            font-size: 20px;
        }
        .post-subtitle, .post-created, a.view-post-link, .short-desc { font-size: 14px; }
    }
}
.post.post-pinned, .post.post-pinned-2 {
    .post-inner .post-content {
        .post-img img {
            height: 240px;
            @media (max-width: $screen-xs-max) { height: 120px; }
        }
        a.post-title {
            font-size: 22px;
        }
        
        .post-subtitle, .post-created, a.view-post-link, .short-desc { font-size: 16px; }
    }
}
.post-top-row {
	margin: 0;
	margin-top: 5px;
	margin-bottom: -10px;
	@media (max-width: $screen-md-max) { font-size: 14px; }
    @media (max-width: $screen-xs-max) { 
		margin-left: -5px;
		margin-right: -5px;
		font-size: 12px; margin-top: 5px; 
	}
}
.post-bottom-row {
	@media (max-width: $screen-md-max) { font-size: 14px; }
    @media (max-width: $screen-xs-max) { 
		font-size: 12px;
	}
}
#back-to-top {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 9999;
    width: 100px;
    height: 64px;
    text-align: center;
    line-height: 30px;
    background: white; //#f5f5f5;
    color: #444;
    border: solid 2px #222;
    cursor: pointer;
    //border: 0;
    border-radius: 4px;
    text-decoration: none;
    transition: opacity 0.2s ease-out;
    opacity: 0;
    @media (max-width: $screen-xs-max) {
        width: 60px;
        height: 40px;
        color: white;
        background-color: #222;
    }
    
    strong {
        font-size: 24px;    
        @media (max-width: $screen-xs-max) {
            font-size: 28px;
        }     
    }
    span {
        @media (max-width: $screen-xs-max) {
            display: none;
        }
    }
}
#back-to-top:hover {
    background: #ffbd00;
}
#back-to-top.show {
    opacity: 1;
}

#choose-fixed, #buttons-fixed {
    position: fixed;
    bottom: 0px; left: 0; right: 0;
    z-index: 9999;
    width: 100%;
    height: 64px;
    text-align: center;
    line-height: 30px;
    background: white; //#f5f5f5;
    color: #444;
	border-top: solid 1px $yellow;
    text-decoration: none;
	
    //transition: opacity 0.2s ease-out;
    //display: none;


}
#buttons-fixed {
	@media (max-width: $screen-sm-max) {
		height: 110px;
		&.short { height: 55px; }
	}
}
/*#choose-fixed:hover {
    background: #ffbd00;
}*/
#choose-fixed.show {
    display: block;
}

.pricing {
    > .row { 
        margin-bottom: 30px; 
        
        .pricing-desc { 
            min-height: 250px;
        }
    }
}

.contact {
    .contact-p {
        font-size: 18px;
        a, span { font-weight: 600; }
    }
}

#embedded-logo {
    
    @media (max-width: $screen-md-max) { margin-left: -5px; width: 200px; }
    @media (max-width: $screen-xs-max) { margin-left: -20px; width: 120px; margin-top: 2px; }
}
#embedded-headline {
    margin-top: 10px; 
    display: inline-block;
    @media (max-width: $screen-md-max) { font-size: 25px; margin-top: 13px; }
    @media (max-width: $screen-xs-max) { font-size: 15px; margin-top: 18px; }
	&.embed-region {
		margin-top: 5px;
		@media (max-width: $screen-md-max) { font-size: 20px; margin-top: 10px; }
		@media (max-width: $screen-xs-max) { font-size: 16px; margin-top: 15px; }
	}
}
.nav-embed {
	position:fixed;
	left: 0; top: 0; right: 0;
	
}

#embedded-logo2 {
    margin-left: -5px; width: 120px; margin-top: -15px; 
    @media (max-width: $screen-md-max) { margin-left: -5px; width: 120px; margin-top: -17px; }
    @media (max-width: $screen-xs-max) { margin-left: -20px; width: 75px; margin-top: -15px; }
}
#embedded-logo3 {
    margin-left: -5px; width: 140px; margin-top: -17px; 
    @media (max-width: $screen-md-max) { margin-left: -5px; width: 135px; margin-top: -17px; }
    @media (max-width: $screen-xs-max) { margin-left: -20px; width: 85px; margin-top: -15px; }
}
#embedded-headline2 {
    margin-top: 10px; 
    //display: inline-block;
    @media (max-width: $screen-md-max) { font-size: 25px; margin-top: 13px; }
    @media (max-width: $screen-xs-max) { font-size: 15px; margin-top: 18px; }
	&.embed-region2 {
		margin-top: 5px;
		@media (max-width: $screen-md-max) { font-size: 20px; margin-top: 10px; }
		@media (max-width: $screen-xs-max) { font-size: 16px; margin-top: 15px; }
	}
}
.nav-embed2 {
	position:fixed;
	left: 0; top: 0; right: 0;
}
.city {
    text-align: center;
    img {
        max-height: 150px;
        max-width: 90%;
        margin: 15px 0 2px 0;
    }
    span {
        font-weight: bold;
        font-size: 20px;
    }
}

.embed-menu {
	position: fixed;
	margin-top: 55px;
	z-index: 1;
    border: solid 1px $yellow;
    border-top: none;
    //margin-top: -22px;
    margin-bottom: 22px;
    text-align: center;
    background-color: $yellow;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    &:first-child {
        border-right-color: $text-color;
    }
    a {
        color: $text-color;
        cursor: pointer;
    }
	&.right {
		left: 50%;
	}
}
.no-border-mobile {
	@media (max-width: $screen-md-min) { border: none; }
}
.mobile-hide {
	@media (max-width: $screen-md-min) { display: none; }
}
.mobile-show {
	display: none;
	@media (max-width: $screen-md-min) { display: block; }
}
.suza-right-menu {
	border: none;
	left: 50%;
	.form-group input {
		min-width: 400px;
		@media (max-width: $screen-lg-min) { min-width: 200px; }
	}
	@media (max-width: $screen-md-min) {
		left: 0; margin-top: 100px;
		border-top: solid 1px black;
		.form-group {
			display: inline-block;
			margin-bottom: 0;
			vertical-align: middle;
			input { min-width: 0; }
		}
	}
}
.suza-body {
	min-height: 600px;
	margin-top: 105px;
	@media (max-width: $screen-md-min) {
		margin-top: 150px;
	}
}
.suza-icon {
	@media (max-width: $screen-md-min) {
		width: 70px;
	}
}
.embed-menu2 {
	position: fixed;
	margin-top: 55px;
	z-index: 1;
    border: solid 1px #fff;
	border-bottom-color: #04abe9;
	border-top-color: #04abe9;
    margin-bottom: 22px;
    text-align: center;
	background-color: #fff;
    
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    &:first-child {
        border-right-color: #04abe9;
    }
    a {
        color: #04abe9;
        cursor: pointer;
    }
	&.right {
		left: 50%;
	}
}
.gt-mini-logo {
	// width: 55px;
	width: 100px;
	@media (max-width: $screen-md-max) { /*width: 45px;*/ width: 75px; }
    @media (max-width: $screen-xs-max) { /*width: 35px;*/ width: 65px; }
}
.table-black {
    padding: 15px;
    background-color: #222;
    border-radius: 15px;
    table {
        width: 100%;
        
        color: white;
        tr {
            border-bottom: solid 2px white;
            td, th {
                border-right: solid 2px white;
                //text-align: center;
                padding: 10px;
                &:last-child {border-right: none;}
            }
            
            
        }
		tr.white {
			cursor: pointer;
			&:hover {
				background-color: $yellow;
				color: $text-color;
			}
			
		}
		tr.type-checked {
			background-color: $yellow;
			color: $text-color;
		}
        tbody tr:last-child {border-bottom: none;}
    }
}
.pricing-head {
    font-size: 16px;
    @media (max-width: $screen-xs-max) { font-size: 14px; }
}
th.pricing-head { color: $yellow; }
.pricing-p {
    @media (max-width: $screen-xs-max) { font-size: 12px; }
}
.select-city {
	background-color: #222;
	font-size: 20px;
	width: 200px;
	padding-left: 3px;
	border-radius: 3px;
	@media (max-width: $screen-md-max) { font-size: 20px; width: 180px; }
	@media (max-width: $screen-xs-max) { font-size: 14px; width: 130px; margin-right: -5px; }
}

div.bold {
	font-weight: bold;
	border: solid 3px black;
	border-radius: 15px;
	margin: 3px;
}
.print-break {page-break-after: always;}
#second-top-menu {
	display: none;
	@media (max-width: $screen-md-max) { display: block; }
}

// profile pages
a.thumbnail, a.thumbnail:hover, a.thumbnail:focus:hover 
{
	color: $text-color !important;
	text-decoration: none; 
	height: 230px;
	@media (max-width: $screen-md-max) { height: 275px; }
	@media (max-width: $screen-xs-max) { height: 300px; }
}
img.move-down {
	margin-top: 30px;
	@media (max-width: $screen-md-max) { margin-top: 40px; }
	@media (max-width: $screen-xs-max) { margin-top: 50px; }
}
.navbar { 
	/*border-bottom: solid 1px #bf4732; */
}
.thumb {
    margin-bottom: 30px;
	text-align: center;
	
}

.thumbnail:hover, .thumbnail:focus {
	border-color: $yellow !important;
}
.thumbnail span { text-decoration: none; font-size: 130%; color: $text-color; }

footer {
    margin: 50px 0;
}

.img-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
    
    
}
.img-modal .caption {
	margin: auto;
	display: block;
	width: 80%;
	max-width: 700px;
	text-align: center;
	color: #ccc;
	padding: 10px 0;
	height: 150px;
}
.img-modal .modal-content {
	margin: auto;
	display: block;
	width: 80%;
	max-width: 700px;
}
/* Add Animation - Zoom in the Modal */
.modal-content, .caption { 
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
    from {-webkit-transform:scale(0)} 
    to {-webkit-transform:scale(1)}
}

@keyframes zoom {
    from {transform:scale(0)} 
    to {transform:scale(1)}
}
/* The Close Button */
.close {
    position: absolute;
    top: 75px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

// pricing order
.order-steps {
	display: none;
	&.active { display: block; }
}
.order-crumbs {
	font-size: 130%;
	.crumbs {
		line-height: 1.5;
		border-radius: 8px;
		float: left;
		display: block;
		width: 25px;
		height: 25px;
		color: #ccc;
		text-align: center;
	}
	.crumbs-step {		
		&.active {
			color: $yellow;
			font-weight: bold;
			font-size: 140%;
			line-height: 1.1;
			//background: $yellow;
		}
	}
	.price {
		float: right;
		display: inline-block;
		font-weight: bold;
		width: 50px;
		text-align: right;
		height: 25px;
	}
}
.crumbs-title {
	display: none;
	&.active { display: block; }
}
.form-group.error {
	input, select, textarea {
		background-color: $brand-danger;
		color: #fff;
	}
}
.checkbox-padding {
	height: 65px;
	padding-top: 10px;
	@media (max-width: $screen-md-max) { padding-top: 0; height: auto; }
}